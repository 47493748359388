import ABItem from "./ABItem";
import questionIcon from '../../../assets/question-icon.svg';
import smileIcon from '../../../assets/smile-icon.svg';

const ABItems = ({items, headline, circleColor, logoColor}) => {
  return ( 
    <section className="ab-items">
      <div className="heading-wrapper">
        <h2>{headline}</h2>
        <span>our team can guide you from A to B</span>
      </div>
      <div>
        <ABItem 
          start={(<img alt='question mark graphic' src={questionIcon} />)} 
          end={(<img alt='graphic of smiling face' src={smileIcon} />)} 
          circleColor={circleColor} 
          logoColor={logoColor}
          showLogo={true}
          noResponsive={true} />
        {
          items.map((ab) => (
            <ABItem 
              key={ab.start} 
              start={ab.start} 
              end={ab.end} 
              circleColor={circleColor} />
          ))
        }
      </div>
    </section>
   );
}

export default ABItems;