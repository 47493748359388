import { Link, useLocation } from "react-router-dom";
import generateLogoSvg from "../../utils/generateLogoSvg";
import AppContactForm from "./AppContactForm";
import closeButtonIcon from '../../assets/times-solid-purple.svg';
import { useCallback, useEffect, useState } from "react";
import formSuccessSource from '../../assets/formsuccess.png';

const getTabbableItems = () => {
  const overlayElement = document.getElementById('contact-modal-overlay');
  const tabbableElements = overlayElement.querySelectorAll('input, textarea, button');

  return Array.from(tabbableElements).filter(
    (elem) => window.getComputedStyle(elem).getPropertyValue('visibility') !== 'hidden'
  );
};

export function closeLetsConnectModal() {
  const modalObj = document.getElementById('contact-modal-overlay');
  modalObj.style.visibility = 'hidden';
  document.body.style.height = 'unset';
  document.body.style.overflowY = 'unset';
}

export function openLetsConnectModal(event) {
  window.myLastFocusedElement = document.activeElement;
  const modalObj = document.getElementById('contact-modal-overlay');
  modalObj.style.visibility = 'visible';
  document.body.style.height = '100vh';
  document.body.style.overflowY = 'hidden';
  modalObj.focus();
}

// Configuration for special events
const eventConfig = {
  'aab052024': {
    title: 'To Agile... and Beyond!',
    description: "Thank you for attending Caitlin's talk! Let's connect and talk more about asynchronous collaboration at your organization. Please share any thoughts or questions below."
  },
  'ADC24': {
    title: 'Embracing Asynchronous Collaboration',
    description: "Thank you for participating in our asynchronous conversation, Let's connect and talk more about how asynchronous collaboration can be leveraged at your organization!"
  },
  // Add more events as needed
};

const LetsConnectModal = () => {
  const [renderState, setRenderState] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSpecialEvent, setIsSpecialEvent] = useState(false);
  const [eventParam, setEventParam] = useState('');
  const [eventInfo, setEventInfo] = useState(null);

  let location = useLocation();
  
  useEffect(() => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const event = searchParams.get('event');
      setEventParam(event);
      // Check if the event is in the config
      if (event && eventConfig[event]) {
        setIsSpecialEvent(true);
        setEventInfo(eventConfig[event]);
      } else {
        setIsSpecialEvent(false);
        setEventInfo(null);
      }
    } catch (error) {
      console.error("Error handling query parameters or opening modal:", error);
    }
  }, [location]);
  
  const closeOverlay = useCallback(() => {
    closeLetsConnectModal();
    setIsSuccess(false);
    setRenderState(renderState + 1);
    window.myLastFocusedElement?.focus();
  }, [setIsSuccess, setRenderState, renderState]);

  const moveFocus = (event, direction) => {
    const filteredList = getTabbableItems();

    const firstElem = filteredList[0];
    const lastElem = filteredList[filteredList.length - 1];

    if (direction === 1 && document.activeElement === lastElem) {
      event.preventDefault();
      firstElem.focus();
    } else if (direction === -1 && document.activeElement === firstElem) {
      event.preventDefault();
      lastElem.focus();
    } else if (!filteredList.includes(document.activeElement)) {
      event.preventDefault();
      filteredList[1].focus();
    }
  };

  useEffect(() => {
    const keyUpListener = (event) => {
      if (event.key === "Escape") {
        // Escape
        closeOverlay();
      } else if (event.shiftKey && event.keyCode === 9) { 
        // Shift + Tab
        moveFocus(event, -1);
      } else if (event.keyCode === 9) { 
        // Tab
        moveFocus(event, 1);
      }
    };
    const overlayElement = document.getElementById('contact-modal-overlay');
    overlayElement.addEventListener("keydown", keyUpListener);
    return () => overlayElement.removeEventListener("keydown", keyUpListener);
  }, [closeOverlay]);

  const colors = {
    logoColors: {
      text: '#00146E',
      line: '#00E9B6',
    },
  };

  return (
    <div id="contact-modal-overlay" tabIndex={0}>
      <div className="top-items show-mobile">
        <div className="back-to-home"></div>
        <Link
          to="/" 
          className="link-with-icon"
          aria-label="Home"
          onClick={closeOverlay}
        >
          <div className="logo-wrapper">
            {generateLogoSvg(colors.logoColors, 'main')}
          </div>
        </Link>
        <CloseButton closeAction={closeOverlay} isIcon={true} />
      </div>
      <article className="content-wrapper">
        <div className="hide-mobile">
          <CloseButton closeAction={closeOverlay} />
        </div>
        {
          isSuccess ? (
            <>
              <img alt="celebration graphic" src={formSuccessSource} className="success-item" />
              <h1 className="success-item">Success!</h1>
              <p className="success-item">Thanks for getting in touch. We’ll reach back out to you via email soon.</p>
              <button onClick={closeOverlay} className="success-item bg-purple">Close</button>
            </>
          ) : (
            <>
              <h1>{isSpecialEvent ? eventInfo.title : "Let’s Connect"}</h1>
              <p>{isSpecialEvent ? eventInfo.description : "Whether it's a quick question about our services or interest in partnering with our team, we'd love to hear from you."}</p>
              <AppContactForm key={renderState} successHandler={setIsSuccess} isSpecialEvent={isSpecialEvent} eventParam={eventParam} />
            </>
          )
        }
      </article>
    </div>
  );
};

const CloseButton = ({ isIcon, closeAction }) => {
  return (
    <button className="menu-icon no-button-styling close-button" onClick={closeAction}>
      {isIcon ? <img src={closeButtonIcon} alt="Close menu" /> : 'Close'}
    </button>
  );
};

export default LetsConnectModal;
