import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../components/AppIcon";
import arrowSvg from '../../../assets/arrow_green.svg';

const contentConfiguration = {
  partner: {
    selectorHeading: 'Your Partner',
    selectorSummary: 'Partner with us to craft creative software solutions for all your business needs.',
    howWeCollaborate: [
      {
        key: 'software-development',
        heading: 'Software Development',
        summary: 'Let us help you where you are, applying software to solve the problems relevant to you by leading and/or integrating with your tech teams.',
      },
      {
        key: 'product-thinking',
        heading: 'Product Thinking',
        summary: 'Hire us to work on your teams alongside your employees to model product thinking, agile practices and improved efficiency.',
      },
      {
        key: 'accessible-design',
        heading: 'Accessible Design',
        summary: 'Partner with us to disseminate best practices for accessible design throughout your organization and teams.',
      },
    ],
  },
  expert: {
    selectorHeading: 'Your Expert',
    selectorSummary: 'Leverage our experience to infuse efficiency, training and inclusivity across your business.',
    howWeCollaborate: [
      {
        key: 'software-development',
        heading: 'Software Development',
        summary: 'Give your tech team a different perspective; we provide an initial analysis and any accompanying seminars or training sessions to enable and revitalize your web-application development.',
      },
      {
        key: 'product-thinking',
        heading: 'Product Thinking',
        summary: 'We examine your current portfolio, processes, teams etc. and work with you to implement incremental changes to help increase efficiency and effectiveness.',
      },
      {
        key: 'accessible-design',
        heading: 'Accessible Design',
        summary: 'Audits, remediations, training \u2014 lean on our standard practices to quickly and effectively ensure your products are inclusive to all customers.',
      },
    ],
  },
};

const MobileCollaborateYourWay = () => {
  return (
    <div className="mobile-wrapper">
      <div>
      {
        Object.entries(contentConfiguration).map(entry => {
          const key = entry[0];
          const item = entry[1];

          return (
            <div
              key={key}
              className="content-wrapper">

              <div className="content">
                <span className="heading">{item.selectorHeading}</span>
                {
                  item.howWeCollaborate.map(whatWeDo => (
                    <div className="what-we-do" key={whatWeDo.key}>
                      <span className="subheading">{whatWeDo.heading}</span>
                      <p>{whatWeDo.summary}</p>
                      <Link to={whatWeDo.key}>
                        <span>Read More</span>
                        <span className="sr-only">about {whatWeDo.key}</span>
                        <img src={arrowSvg} alt="arrow"/>
                      </Link>
                      <AppIcon name={whatWeDo.key} offsetY={'4px'}/>
                    </div>
                  ))
                }
              </div>
            </div>
          );
        })
      }
      </div>
    </div>
  )
};

const DesktopCollaborateYourWay = () => {

  const DEFAULT_KEY = 'partner';
  const [selection, setSelection] = useState(DEFAULT_KEY);
  const [svgLineDetails, setSvgLineDetails] = useState(null);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const drawLineDetails = useCallback(
    () => {
      // this is comically confusing.. but.. it works.
      const selectedSelector = document.querySelector('.selectors-wrapper .selected');
      const elemRect = selectedSelector.getBoundingClientRect();
    
      const containerRect = containerRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
    
      const lines = {
        selectorVertical: '',
        connectingHorizontal: '',
        contentVertical: ''
      };
    
      const roundedAmount = 10;
    
      const extraVertical = 30;
      const selectorX = (elemRect.x - containerRect.x) + (elemRect.width * 0.67)
      const selectorYtop = elemRect.y - containerRect.y;
      const selectorYbottom = selectorYtop + elemRect.height;
      const selectorVerticalTop = selectorYtop + extraVertical - roundedAmount;
      const selectorVerticalBottom = (containerRect.height - selectorYbottom) + extraVertical - roundedAmount;
      
      const contentX = (contentRect.x - containerRect.x) + (contentRect.width * 0.33)
      const contentY = contentRect.y - containerRect.y;
      const contentVertical = contentY + extraVertical - roundedAmount;
      
      const connectingHorizontal = contentX - selectorX - roundedAmount;
      
      let selectorVerticalString = `-${selectorVerticalTop}`;
      let contentVerticalString = contentVertical;
      let connectingHorizontalString = connectingHorizontal;
      
      lines.roundedSelector = `q 0,-${roundedAmount} ${roundedAmount},-${roundedAmount}`;
      lines.roundedContent = `q ${roundedAmount},0 ${roundedAmount},${roundedAmount}`;
      
      let startingPoint = `${selectorX},${selectorYtop}`;
    
    
      if(selection === 'expert'){
        startingPoint = `${selectorX},${selectorYbottom}`
    
        selectorVerticalString = `${selectorVerticalBottom}`;
        contentVerticalString = `-${contentVertical}`;
        connectingHorizontalString = connectingHorizontal;
        
        lines.roundedSelector = `q 0,${roundedAmount} ${roundedAmount},${roundedAmount}`;
        lines.roundedContent = `q ${roundedAmount},0 ${roundedAmount},-${roundedAmount}`;
      }
    
    
      lines.selectorVertical = `l 0,${selectorVerticalString}`;
      lines.contentVertical = `l 0,${contentVerticalString}`;
      lines.connectingHorizontal = `l ${connectingHorizontalString},0`;
      
      const dataToSet = `M ${startingPoint} ${lines.selectorVertical} ${lines.roundedSelector} ${lines.connectingHorizontal} ${lines.roundedContent} ${lines.contentVertical}`;
      setSvgLineDetails(dataToSet)
    }, 
    [selection]
  );

  useEffect(() => {
    drawLineDetails();
  }, [selection, drawLineDetails]);

  useEffect(() => {
    window.addEventListener('resize', drawLineDetails);
    return () => window.removeEventListener('resize', drawLineDetails);
  });

  useEffect(() => {
    setTimeout(drawLineDetails, 60);
    setTimeout(drawLineDetails, 220);
  });

  return ( 
    <div className="desktop" ref={containerRef}>
      {
        svgLineDetails && (
          <svg>
            <path d={svgLineDetails} />
          </svg>
        )
      }
      <div className="selectors-wrapper">
        {
          Object.entries(contentConfiguration).map(entry => {
            const key = entry[0];
            const item = entry[1];
            return (
              <button 
                className={`no-button-styling ${key === selection && 'selected'}`} 
                onClick={() => setSelection(key)} 
                key={key}>
                <span className="subheading">{item.selectorHeading}</span>
                <p>{item.selectorSummary }</p>
              </button>
            )
          })
        }
      </div>
      <div className="content-wrapper" aria-live="polite">
        <div className="content" ref={contentRef}>
          {
            contentConfiguration[selection]?.howWeCollaborate.map(whatWeDo => (
              <div className="what-we-do" key={whatWeDo.key}>
                <span className="subheading">{whatWeDo.heading}</span>
                <p>{whatWeDo.summary}</p>
                <Link to={whatWeDo.key}>
                  <span>Read More</span>
                  <span className="sr-only">about {whatWeDo.key}</span>
                  <img src={arrowSvg} alt="arrow"/>
                </Link>
                <AppIcon name={whatWeDo.key} offsetY={'4px'}/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

const CollaborateYourWay = () => {
  return (
    <div className="collaborate-your-way">
      <div className="hide-mobile">
        <DesktopCollaborateYourWay />
      </div>
      <div className="show-mobile">
        <MobileCollaborateYourWay />
      </div>
    </div>
  )
}

export default CollaborateYourWay;