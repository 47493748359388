const colorMappings = {
  gray: '#F0F0F0',
  pink: '#D52E66',
  purple: '#7635F8',
}
function generateHeaderBgSvg(color) {
  const hexCode = colorMappings[color];
  return (
    <>
      <div 
        className="top-bg-fill"
        style={{
          backgroundColor: hexCode,
        }}></div>
      <svg
        className="svg-bg" 
        viewBox="0 0 1440 486" 
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M846.055 391.622C668.319 335.05 480.866 345.276 289.077 422.009C261.153 433.259 233.305 443.973 204.927 454.036C137.615 475.475 63.2583 498.337 2.31348 459.866C1.5415 459.376 0.770508 458.873 0 458.355V0H1440V342.553C1359.26 442.68 1265.13 484.27 1178.52 485.707C1096.73 487.064 1019.27 456.863 941.792 426.651C909.985 414.25 878.174 401.846 846.055 391.622Z" fill={hexCode}/>
      </svg>
    </>
  )
}

export const pregeneratedSvgs = Object.keys(colorMappings).reduce((result, nextKey) => ({
  ...result,
  [nextKey]: generateHeaderBgSvg(nextKey)
}), {});

export default generateHeaderBgSvg;