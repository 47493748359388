import { Link } from "react-router-dom";
import generateLogoSvg from "../../../utils/generateLogoSvg";
import NavUnorderedList from "./NavUnorderedList";

const DesktopNav = ({logoColor}) => {
  return (
    <div id="desktop-nav" className="page-content">
      <BackToHome color={logoColor} />
      <NavUnorderedList />
    </div>
  );
}
const BackToHome = ({color}) => {
  return (
    <Link to="/" className="link-with-icon">
      <div className="logo-wrapper">
        { generateLogoSvg(color) }
      </div>
      <span>Back to Home</span>
    </Link>
  );
}
export default DesktopNav;