import logo from '../../assets/blue_teal_home.svg';
import homeHero from '../../assets/ed-software.svg';
import { Link } from "react-router-dom";
import AppFooter from '../components/AppFooter';
import ThreeTiles from './components/ThreeTiles';
import AppBackgroundBlob from '../components/AppBackgroundBlob';
import CollaborateYourWay from './components/CollaborateYourWay';
import LetsConnectEntrypoint from './components/LetsConnectEntrypoint';
import MobileNav from '../components/Nav/MobileNav';
import { useEffect, useState } from 'react';
import StandUp from './components/StandUp';
import WhyEnDash from './components/WhyEnDash';

const HomePage = () => {
  const [floating, setFloating] = useState(false);

  useEffect(() => {
    document.title = 'En Dash Consulting';
    const onScroll = () => setFloating(window.pageYOffset > 200);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const floatingClass = floating ? 'floating' : '';

	return ( 
		<>
      <header className="page-content homepage">
        <nav className={`show-mobile mobile-nav-wrapper ${floatingClass}`}>
          <MobileNav />
        </nav>
        <div className="hero">
          <img src={homeHero} alt="En Dash Product Development" />
        </div>
        <nav className="hide-mobile">
					<ul>
						<li><Link to="/software-development">Software Development</Link></li>
						<li><Link to="/product-thinking">Product Thinking</Link></li>
						<li><Link to="/accessible-design">Accessible Design</Link></li>
					</ul>
        </nav>
        <div className="logo hide-mobile">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <p className="text">
          En Dash helps bridge technology gaps. 
          Partner with us to empower your business with innovative solutions.
        </p>
      </header>
      <main className="hide-x">
        <section className="mobile-fullbleed tablet-fullbleed">
          <h2 className='page-content'>We can get you from A to B with creative solutions</h2>
          <ThreeTiles />
          <AppBackgroundBlob color="#00E9B6" position={{x: '-60%', y: '30%'}} />
        </section>
        <section className="mobile-fullbleed">
          <h2 className='page-content'>Collaborate with Our team Your way</h2>
          <CollaborateYourWay />
        </section>
        <section>
          <h2>Explore our suite of n-app products</h2>
          <StandUp />
        </section>
        <section>
          <h2>Why En Dash?</h2>
          <WhyEnDash />
        </section>
        <LetsConnectEntrypoint />
      </main>
      <AppFooter styleVariant={0} />
    </>
	);
}
 
export default HomePage;