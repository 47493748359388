import { Link, useLocation } from 'react-router-dom';
import backArrow from '../../../assets/back-arrow.svg';
import hamburgerIcon from '../../../assets/bars-solid.svg';
import generateLogoSvg from '../../../utils/generateLogoSvg';
import { openMobileNavOverlay } from './MobileNavOverlay';

const MobileNav = ({logoColor}) => {
  const isOnHomePage = useLocation().pathname === '/';
  return ( 
    <div id="mobile-nav">
      {
        isOnHomePage ?
          <div className="back-to-home"></div> :
          <Link to="/" className="link-with-icon back-to-home">
            <img src={backArrow} alt="Back to home"/>
          </Link>
      }
      <Link to="/" className="link-with-icon">
        <div className="logo-wrapper">
          { generateLogoSvg(logoColor, 'main') }
        </div>
      </Link>
      <HamburgerIcon />
    </div>
   );
}

const HamburgerIcon = () => {
  const openMenu = () => {
    openMobileNavOverlay();
  }
  return ( 
    <button className="menu-icon no-button-styling hamburger-icon" onClick={openMenu}>
      <img src={hamburgerIcon} alt="Menu Icon" />
    </button>
   );
}

export default MobileNav;