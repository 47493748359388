import { Link } from "react-router-dom";
import generateLogoSvg from "../../../utils/generateLogoSvg";
import closeButtonIcon from '../../../assets/times-solid.svg';
import NavUnorderedList from "./NavUnorderedList";

export function closeMobileNavOverlay() {
  const modalObj = document.getElementById('mobile-nav-overlay');
  modalObj.style.visibility = 'hidden';
  document.body.style.height = 'unset';
  document.body.style.overflowY = 'unset';
}

export function openMobileNavOverlay() {
  const modalObj = document.getElementById('mobile-nav-overlay');
  modalObj.style.visibility = 'visible';
  document.body.style.height = '100vh';
  document.body.style.overflowY = 'hidden';
}

const MobileNavOverlay = () => {
  const colors = {
    logoColors: {
      text: '#FFFFFF',
      line: '#FFFFFF',
    },
    background: '#001769',
    navItemsColor: '#00E5B9',
  };

  const closeNavOverlay = () => {
    closeMobileNavOverlay();
  }

  return ( 
    <div 
      id="mobile-nav-overlay"
      style={{
        backgroundColor: colors.background,
        color: colors.navItemsColor,
      }}>
      <article 
        className="content-wrapper" 
      >
        <div className="top-items">
          <div className="back-to-home"></div>
          <Link 
            to="/" 
            className="link-with-icon"
            aria-label="Home"
            onClick={closeNavOverlay}>
            <div className="logo-wrapper">
              { generateLogoSvg(colors.logoColors, 'main') }
            </div>
          </Link>
          <CloseButton />
        </div>
        <nav>
          <NavUnorderedList callbackFn={closeNavOverlay} />
        </nav>
      </article>
    </div>
   );
}

const CloseButton = () => {
  const closeNavOverlay = () => {
    closeMobileNavOverlay();
  }
  return ( 
    <button className="menu-icon no-button-styling" onClick={closeNavOverlay}>
      <img src={closeButtonIcon} alt="Close menu" />
    </button>
   );
}
 
export default MobileNavOverlay;