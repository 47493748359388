import ABItems from "./components/ABItems";
import AppFooter from "./components/AppFooter";
import HowWeDoIt from "./components/HowWeDoIt";
import SubpageHeader from "./components/SubpageHeader";

const pageContent = {
	variant: {
    key: 'accessible-design',
		title: 'Accessible Design',
		logoColor: 'green',
		headerBgColor: 'purple',
		headerTextColor: 'white',
		imgAlt: 'Graphic of people thinking about and using technology, including the use of assistive technologies such as screen-readers. Also includes some web-accessibility development graphics like hexcodes',
	},
	summary: (
		<>
			<p className="page-content padded">At En Dash, we are committed to improving the relationship between humans and the software they rely on. That means improving existing software products to ensure that all users have a comparable and positive experience.</p>
			<p className="page-content padded">Accessible design is about inclusivity — it is not an afterthought, it is part of the product development process.</p>
		</>
	),
	abHeadline: 'Build Products For All Audiences',
	abContent: [
		{
			start: 'Not responsive',
			end: 'Flexible and fluid layouts',
		},
		{
			start: 'Unusable by non-mouse users',
			end: 'Tab key capable',
		},
		{
			start: 'ADA litigation',
			end: 'Safety through compliance',
		},
		{
			start: 'Unaware of A11Y standards',
			end: 'Education and consulting',
		},
		{
			start: 'Messy to screen-reader',
			end: 'Semantic HTML',
		},
	],
	howWeDoItContent: [
		{
			heading: 'Your Partner',
			text: 'Partner with us to disseminate best practices for accessible design throughout your organization and teams.',
		},
		{
			heading: 'Your Expert',
			text: 'Audits, remediations, training — lean on our standard practices to quickly and effectively ensure your products are inclusive to all customers.',
		},
	]
};


const AccessibilityPage = () => {
	return ( 
		<>
			<SubpageHeader variant={pageContent.variant} />
			<main className="page-content">
				{ pageContent.summary }
				<ABItems 
					items={pageContent.abContent} 
					headline={pageContent.abHeadline} 
					circleColor={pageContent.variant.headerBgColor}
					logoColor={pageContent.variant.logoColor}
					/>
				
				<HowWeDoIt 
					iconKey={pageContent.variant.key}
					headingColor={pageContent.variant.headerBgColor}
					content={pageContent.howWeDoItContent} />
			</main>
			<AppFooter />
		</>
	);
}
 
export default AccessibilityPage;