import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import './App.scss';
import 'focus-visible';
import HomePage from './views/HomePage/HomePage';
import SoftwarePage from './views/SoftwarePage';
import ProductPage from './views/ProductPage';
import AccessibilityPage from './views/AccessibilityPage';
import ScrollToTop from "./utils/ScrollToTop";
import LetsConnectModal, { openLetsConnectModal } from "./views/components/LetsConnectModal";
import MobileNavOverlay from "./views/components/Nav/MobileNavOverlay";

const queryMatchers = [
  {
    queryKVs: { qrLocation: 'handout', event: 'aab052024' },
    action: openLetsConnectModal
  },
  {
    queryKVs: { qrLocation: 'meetup', event: 'ADC24' },
    action: openLetsConnectModal
  },
];

function checkQueryParameters(searchParams) {
  queryMatchers.forEach(({ queryKVs, action }) => {
    const matches = Object.entries(queryKVs).every(
      ([key, value]) => searchParams.get(key) === value
    );
    if (matches) {
      action();
    }
  });
}

function App() {
  let location = useLocation();
  const lastLocation = useRef(null);

  useEffect(() => {
    try {
      if (lastLocation.current) {
        let mainElem = document.querySelector("header h1");
        if (mainElem) {
          mainElem.focus();
        }
      }
      lastLocation.current = location;

      // Check query parameters
      const searchParams = new URLSearchParams(location.search);
      checkQueryParameters(searchParams);
    } catch (error) {
      console.error("Error handling query parameters or opening modal:", error);
    }
  }, [location]);

  return (
    <>
      <MobileNavOverlay />
      <LetsConnectModal />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="software-development" element={<SoftwarePage />} />
        <Route path="product-thinking" element={<ProductPage />} />
        <Route path="accessible-design" element={<AccessibilityPage />} />
      </Routes>
    </>
  );
}

export default App;
