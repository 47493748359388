import React, { useState, useImperativeHandle, useRef } from "react";

const AppInputField = React.forwardRef(({
  label,
  name,
  value,
  setter,
  required,
  type,
  textarea,
  customValidator,
  customErrorMessage,
  disabled,
}, ref) => {
  const labelString = label + (required ? '*' : ' (optional)');
  const [isValid, setIsValid] = useState(true);
  const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
  const inputRef = useRef(null);

  const changeAndBlurHandler = (valueToCheck, isBlurEvent) => {
    let validityCheck = true;
    if(isBlurEvent) {
      setHasBeenBlurred(true);
    }

    if((hasBeenBlurred || isBlurEvent) && customValidator) {
      validityCheck = customValidator(valueToCheck);
    }

    if(validityCheck && required) {
      validityCheck = !!(valueToCheck);
    }

    setIsValid(validityCheck);
    setter(valueToCheck);

    return validityCheck;
  }

  const validate = () => {
    return changeAndBlurHandler(value);
  }

  const focus = () => {
    inputRef.current.focus();
  }

  useImperativeHandle(ref, () => ({
    validate,
    focus
  }));

  return (
    <div className={`input-field ${textarea ? 'span2' : ''}`}>
      <label htmlFor={`input-${name}`}>{labelString}</label>
      {
        textarea ?
          (
            <textarea
              type={type ?? 'text'}
              id={`input-${name}`} 
              name={name}
              value={value} 
              disabled={disabled}
              onChange={(event) => changeAndBlurHandler(event.target.value)}
              onBlur={(event) => changeAndBlurHandler(event.target.value, true)}
              className={isValid ? '' : 'invalid'}
              ref={inputRef}
              required />
          ) :
          (
            <input
              type="text"
              id={`input-${name}`} 
              name={name}
              value={value} 
              disabled={disabled}
              onChange={(event) => changeAndBlurHandler(event.target.value)}
              onBlur={(event) => changeAndBlurHandler(event.target.value, true)}
              className={isValid ? '' : 'invalid'}
              ref={inputRef}
              required />
          )
      }
      {!isValid && <span className="error-message">{customErrorMessage || 'Invalid input.'}</span>}
    </div>
  );
});

export default AppInputField;