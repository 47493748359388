import AppBackgroundBlob from "../../components/AppBackgroundBlob";
import standupDesktop from '../../../assets/standup-desktop.png';
import standupMobile from '../../../assets/standup-mobile.png';
import { openLetsConnectModal } from "../../components/LetsConnectModal";

const StandUp = () => {
  const openModal = () => {
    openLetsConnectModal();
  }

  return ( 
    <div className="n-standup page-content">
      <div className="info">
        <h3>n-STANDUP</h3>
        <p>In an increasingly asynchronous and remote work environment, empower your team in their transition to new ways of working with our StandUp application — a tool that assists teams in their mission to be more agile in an asynchronous space by allowing everyone to share work progress regardless of time zone or schedules.</p>
        <button
            onClick={openModal}
          >Get in Touch</button>
      </div>
      <div className="graphic">
        <img className='desktop' alt="n-standup webapp shown on desktop" src={standupDesktop} />
        <img className='mobile' alt="n-standup webapp shown on mobile" src={standupMobile} />
        <AppBackgroundBlob 
          color="#6C41F0" 
          position={{x: '0%', y: '0%'}} 
          styleChoice={1} 
          width={'185%'}
          />
      </div>
    </div>
   );
}
 
export default StandUp;