import { useNavigate } from 'react-router-dom';
import AppIcon from '../../components/AppIcon';

const tileConfig = [
  {
    tagline: 'Stuck in a tech rut?',
    heading: 'Software Development',
    text: "Whether it's a custom solution, upgrading your current technology stack, or migrating to the cloud, En Dash can work with you to identify and solve your software needs.",
    route: '/software-development',
    id: 'software-development'
  },
  {
    tagline: 'Need a boost in efficiency?',
    heading: 'Product Thinking',
    text: 'As a small business, we face problems that can be solved with software every day. Our backlog of products are custom solutions we have built to help us improve efficiencies in our personal and work lives.',
    route: '/product-thinking',
    id: 'product-thinking'
  },
  {
    tagline: 'Looking for digital inclusivity?',
    heading: 'Accessible Design',
    text: 'Our expertise in ensuring compliance with WCAG, Section 508 and A11y standards can support your company through preparing for an accessibility audit through any remediation efforts you may need.',
    route: '/accessible-design',
    id: 'accessible-design'
  },
]
const ThreeTiles = () => {
  return (
    <div className='three-tiles'>
        <div className='tile-wrapper'>
          {
            tileConfig.map(tile => (
              <TopTile 
                key={tile.id}
                id={tile.id}
                heading={tile.heading} 
                tagline={tile.tagline}
                text={tile.text}
                route={tile.route}
              /> 
            ))
          }
        </div>
    </div>
  )
}

const TopTile = (props) => {
  let navigate = useNavigate();

  const navigateFunction = (event) => {
    event.preventDefault();
    navigate(props.route);
  }
  
  return (
    <a className="no-link-styling" onClick={navigateFunction} href={props.route}>
      <span className="tagline">{props.tagline || 'no tagline here'}</span>
      <AppIcon name={props.id} />
      <span className="subheading">{props.heading}</span>
      <span className="text">{props.text}</span>
    </a>
  )
}

export default ThreeTiles;