import AppBackgroundSwirl from "../../components/AppBackgroundSwirl";

const blurbs = [
  {
    text: 'Small team, but experience working in a variety of company-sizes',
    cssClasses: 'two',
  },
  {
    text: 'Mission: Improve the human–software relationship by creating interactions where software is enabling, facilitating, and augmenting our human experiences.',
    cssClasses: 'one',
  },
  {
    text: 'With our learning-first mindset, we are constantly exploring, experimenting, and creating.',
    cssClasses: 'three',
  },
  {
    text: 'Primarily based in East Coast, USA; specialized in remote work',
    cssClasses: 'four',
  },
]

const WhyEnDash = () => {
  return ( 
    <div className="why-en-dash page-content">
      {
        blurbs.map(blurb => (
          <div key={blurb.cssClasses} className={`blurb ${blurb.cssClasses}`}>
            <span>{blurb.text}</span>
          </div>
        ))
      }
      <AppBackgroundSwirl 
        color="#8F63FB" 
        position={{x: '-35%', y: '0%', rotate: '-7deg'}} 
        width={'50%'}/>
    </div>
   );
}
 
export default WhyEnDash;