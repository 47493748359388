import { openLetsConnectModal } from "../../components/LetsConnectModal";

const LetsConnectEntrypoint = () => {
  const openModal = () => {
    openLetsConnectModal();
  }

  return ( 
    <div className="fullbleed bg-purple inverted-colors">
      <section className="lets-connect-entrypoint">
        <h2>Ready to start? Let's grab a coffee</h2>
        <div className="centered">
          <button
            onClick={openModal}
          >Get in Touch</button>
        </div>
      </section>
    </div>
   );
}
 
export default LetsConnectEntrypoint;