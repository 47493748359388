const colorMappings = {
  green: {
    bg: '#00BD81',
    text: '#FFFFFF',
    line: '#000000',
  },
  blue: {
    bg: '#001769',
    text: '#FFFFFF',
    line: '#00E5B9',
  },
  orange: {
    bg: '#FF5926',
    text: '#FFFFFF',
    line: '#000000',
  },
}

const defaultMainColors = {
  text: '#00146E',
  line: '#00E9B6',
};

const generateLogoSvg = (color, type = 'square') => {
  const fills = colorMappings[color];

  const renderId = Date.now();
  if(type === 'main'){
    const mainFills = (typeof color === 'object') ? color : defaultMainColors;

    return (
      <svg viewBox="0 0 228 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>En Dash Consulting Logo</title>
        <g clipPath={`url(#clip${renderId})`}>
          <path d="M64.1277 48.7153H4.4521V52.9008H64.1277V48.7153Z" fill={mainFills.line} />
          <path d="M69.2123 52.6663C68.803 52.4434 68.4806 52.1462 68.245 51.7623C68.0093 51.3784 67.8977 50.9326 67.8977 50.4497C67.8977 49.9667 68.0093 49.5209 68.245 49.1371C68.4806 48.7532 68.803 48.4436 69.2123 48.2331C69.6215 48.0226 70.0804 47.9111 70.6012 47.9111C71.0477 47.9111 71.4569 47.9854 71.8166 48.1464C72.1762 48.3074 72.4739 48.5303 72.7095 48.8275L71.829 49.6324C71.5065 49.2485 71.1221 49.0628 70.6757 49.0628C70.4152 49.0628 70.1672 49.1247 69.9688 49.2361C69.7579 49.3476 69.5967 49.5209 69.4851 49.7315C69.3735 49.942 69.3115 50.1896 69.3115 50.4621C69.3115 50.7345 69.3735 50.9822 69.4851 51.1927C69.5967 51.4032 69.7579 51.5642 69.9688 51.688C70.1796 51.7995 70.4152 51.8614 70.6757 51.8614C71.1345 51.8614 71.5189 51.6756 71.829 51.2917L72.7095 52.0966C72.4739 52.3938 72.1762 52.6291 71.8166 52.7777C71.4569 52.9387 71.0477 53.013 70.6012 53.013C70.0804 53.0006 69.6215 52.8892 69.2123 52.6663Z" fill={mainFills.text}/>
          <path d="M75.6982 52.6656C75.289 52.4427 74.9541 52.1455 74.7185 51.7492C74.4829 51.3653 74.3589 50.9196 74.3589 50.4366C74.3589 49.9537 74.4705 49.5203 74.7185 49.124C74.9541 48.7401 75.2766 48.4305 75.6982 48.2076C76.1075 47.9847 76.5787 47.8733 77.0996 47.8733C77.6204 47.8733 78.0793 47.9847 78.5009 48.2076C78.9102 48.4305 79.245 48.7277 79.4806 49.124C79.7163 49.5079 79.8403 49.9537 79.8403 50.4366C79.8403 50.9196 79.7163 51.3653 79.4806 51.7492C79.245 52.1331 78.9226 52.4427 78.5009 52.6656C78.0917 52.8885 77.6204 52.9999 77.0996 52.9999C76.5787 52.9999 76.1199 52.8885 75.6982 52.6656ZM77.7692 51.6749C77.9677 51.5635 78.1289 51.3901 78.2405 51.1796C78.3521 50.9691 78.4141 50.7214 78.4141 50.449C78.4141 50.1766 78.3521 49.9289 78.2405 49.7184C78.1289 49.5079 77.9677 49.3469 77.7692 49.2231C77.5708 49.1116 77.3476 49.0497 77.0996 49.0497C76.8515 49.0497 76.6283 49.1116 76.4299 49.2231C76.2315 49.3345 76.0703 49.5079 75.9586 49.7184C75.847 49.9289 75.785 50.1766 75.785 50.449C75.785 50.7214 75.847 50.9691 75.9586 51.1796C76.0703 51.3901 76.2315 51.5511 76.4299 51.6749C76.6283 51.7864 76.8515 51.8483 77.0996 51.8483C77.3476 51.8483 77.5708 51.7864 77.7692 51.6749Z" fill={mainFills.text}/>
          <path d="M86.6363 47.9971V52.9008H85.4953L83.3251 50.288V52.9008H81.9609V47.9971H83.1019L85.2721 50.6099V47.9971H86.6363Z" fill={mainFills.text}/>
          <path d="M89.5878 52.8512C89.2158 52.7522 88.9057 52.6283 88.6825 52.455L89.1413 51.4272C89.3646 51.5758 89.6126 51.6872 89.9102 51.7739C90.1955 51.8606 90.4807 51.9101 90.7659 51.9101C91.2992 51.9101 91.5596 51.7739 91.5596 51.5138C91.5596 51.3776 91.4852 51.2662 91.3364 51.2043C91.1876 51.1423 90.9396 51.068 90.6047 50.9937C90.2327 50.9194 89.9226 50.8328 89.6746 50.7337C89.4266 50.647 89.2158 50.4984 89.0421 50.3003C88.8685 50.1022 88.7817 49.8297 88.7817 49.483C88.7817 49.1858 88.8685 48.9134 89.0297 48.6781C89.191 48.4428 89.439 48.2447 89.7614 48.1085C90.0839 47.9722 90.4807 47.8979 90.952 47.8979C91.2744 47.8979 91.5968 47.9351 91.9069 48.0094C92.2169 48.0837 92.5021 48.1828 92.7378 48.3314L92.3161 49.3592C91.8449 49.1115 91.3984 48.9753 90.952 48.9753C90.6791 48.9753 90.4807 49.0124 90.3443 49.0991C90.2203 49.1858 90.1583 49.2849 90.1583 49.4211C90.1583 49.5573 90.2327 49.644 90.3815 49.7183C90.5303 49.7802 90.7659 49.8545 91.1008 49.9164C91.4728 49.9907 91.7829 50.0774 92.0309 50.1765C92.2789 50.2631 92.4897 50.4117 92.6634 50.6099C92.8494 50.808 92.9362 51.0804 92.9362 51.4148C92.9362 51.712 92.8494 51.972 92.6882 52.2073C92.5269 52.4426 92.2789 52.6407 91.9565 52.7769C91.634 52.9255 91.2372 52.9874 90.7659 52.9874C90.3443 52.9998 89.9598 52.9503 89.5878 52.8512Z" fill={mainFills.text}/>
          <path d="M95.5157 52.4056C95.1064 52.0093 94.908 51.4397 94.908 50.7214V48.0095H96.297V50.6843C96.297 51.4644 96.607 51.8607 97.2271 51.8607C97.8472 51.8607 98.1572 51.4644 98.1572 50.6843V48.0095H99.5213V50.7214C99.5213 51.452 99.3229 52.0093 98.9137 52.4056C98.5044 52.8018 97.9464 52.9999 97.2147 52.9999C96.483 52.9999 95.9249 52.8018 95.5157 52.4056Z" fill={mainFills.text}/>
          <path d="M101.865 47.9971H103.254V51.7987H105.598V52.9008H101.865V47.9971Z" fill={mainFills.text}/>
          <path d="M108.14 49.0992H106.627V47.9971H111.03V49.0992H109.529V52.9008H108.14V49.0992Z" fill={mainFills.text}/>
          <path d="M112.952 47.9971H114.341V52.9008H112.952V47.9971Z" fill={mainFills.text}/>
          <path d="M121.41 47.9971V52.9008H120.269L118.099 50.288V52.9008H116.734V47.9971H117.875L120.046 50.6099V47.9971H121.41Z" fill={mainFills.text}/>
          <path d="M127.077 50.3375H128.305V52.3683C128.02 52.5665 127.697 52.7275 127.338 52.8265C126.978 52.9256 126.606 52.9875 126.246 52.9875C125.738 52.9875 125.267 52.8761 124.857 52.6655C124.448 52.4426 124.126 52.1454 123.89 51.7616C123.654 51.3777 123.543 50.9319 123.543 50.449C123.543 49.966 123.654 49.5202 123.89 49.1363C124.126 48.7525 124.448 48.4429 124.87 48.2324C125.291 48.0219 125.75 47.9104 126.284 47.9104C126.742 47.9104 127.152 47.9847 127.524 48.1457C127.883 48.2943 128.193 48.5172 128.441 48.8144L127.561 49.6193C127.226 49.2602 126.829 49.0744 126.37 49.0744C125.949 49.0744 125.614 49.1983 125.353 49.4583C125.093 49.7183 124.969 50.0527 124.969 50.4737C124.969 50.7461 125.031 50.9814 125.143 51.1919C125.254 51.4025 125.428 51.5758 125.639 51.6873C125.85 51.8111 126.085 51.8606 126.358 51.8606C126.618 51.8606 126.879 51.8111 127.114 51.6997V50.3375H127.077Z" fill={mainFills.text}/>
          <path d="M25.4601 32.4314L26.9483 33.7316C25.7701 36.0225 21.8017 39.9232 14.9313 39.9232C7.19282 39.9108 0 35.0318 0 25.6206C0 15.9122 7.67647 11.0828 15.4274 11.0828C22.0621 11.0828 27.2583 14.6739 27.2583 20.172C27.2583 25.0634 23.1658 25.7444 16.6055 25.9921L9.78472 26.2398C8.66859 26.3017 8.42056 27.2304 8.7926 28.6545C10.3428 33.1124 13.9392 35.651 18.5153 35.651C20.884 35.6386 23.2899 35.0318 25.4601 32.4314ZM7.9245 21.4723C7.8625 23.3297 8.48257 24.0727 10.0948 24.0727H13.3811C18.7137 24.0727 20.3259 22.401 20.3259 19.5529C20.3259 16.3951 18.2177 13.4232 14.4973 13.4232C10.2808 13.4356 8.23454 16.779 7.9245 21.4723Z" fill={mainFills.text}/>
          <path d="M53.5245 34.7223V23.466C53.5245 18.8842 51.6023 15.8008 47.8198 15.8008C43.8514 15.8008 41.4331 18.8966 41.4331 23.466V33.6697C41.4331 36.3321 42.4872 37.1989 45.3396 37.4466V39.5517H30.0486V37.4466C32.9629 37.1989 34.0171 36.3321 34.0171 33.6697V18.2651C34.0171 15.4169 32.8389 14.6739 30.0486 14.9216V12.8165L38.7172 11.2066C40.5154 10.8971 41.4455 11.702 41.4455 13.126C41.4455 15.355 42.4376 15.9123 43.2437 14.9835C44.6079 13.4975 47.0882 10.959 51.9743 10.959C56.5008 10.959 60.9529 13.188 60.9529 21.8438V34.0288C60.9529 36.0721 62.0071 36.8151 64.1153 36.3816V38.4868C62.9992 39.3536 60.7049 39.8489 58.9067 39.8489C55.5087 39.8489 53.5245 38.0534 53.5245 34.7223Z" fill={mainFills.text}/>
          <path d="M67.9101 37.3725C70.5764 37.001 72.1266 36.0104 72.4367 33.6575L75.723 6.7489C76.0331 4.3961 74.7309 3.40544 72.1266 3.03395L72.3746 0.990723H88.6081C99.943 0.990723 107.136 5.38675 107.136 16.8907C107.136 30.0664 98.5292 39.2919 85.1357 39.2919C82.7174 39.2919 78.2529 38.9204 75.103 38.9204C72.6227 38.9204 70.2044 39.1061 67.6621 39.4157L67.9101 37.3725ZM98.4548 16.5811C98.4548 7.98722 94.9824 3.46736 88.6701 3.46736H87.864C85.3217 3.46736 84.0196 4.3961 83.7095 6.7489L80.4232 33.6575C80.1131 36.3199 82.2834 36.8153 84.3296 36.8153C93.8043 36.8153 98.4548 26.5991 98.4548 16.5811Z" fill={mainFills.text}/>
          <path d="M106.689 37.3723C110.1 36.6912 111.588 34.8338 113.324 31.6141L126.581 7.12021C128.069 4.334 128.627 2.16694 128.813 0.99054L133.278 0.557129L142.257 30.1282C143.683 34.7718 144.117 36.7532 147.031 37.4342L146.783 39.4155H131.046L131.294 37.3723C134.952 36.8151 135.138 35.0814 134.332 32.481L133.65 30.3139C133.216 28.9518 132.162 28.6422 130.612 28.6422H120.889C119.277 28.6422 118.223 29.0137 117.355 30.5616L116.486 32.1095C114.812 35.0814 116.114 36.8151 119.773 37.3723L119.525 39.4155H106.441L106.689 37.3723ZM130.847 25.856C131.777 25.856 132.149 25.3606 131.901 24.4938L128.429 13.1137C128.119 12.0611 127.313 11.9992 126.817 12.9279L120.554 24.4319C120.058 25.3606 120.554 25.856 121.298 25.856H130.847V25.856Z" fill={mainFills.text}/>
          <path d="M151.979 36.7533C150.801 32.3572 151.297 26.8591 154.026 26.8591C154.708 26.8591 154.894 27.1687 155.142 28.2213C156.568 34.2271 160.784 37.1248 165.67 37.1248C170.259 37.1248 172.801 34.5243 172.801 30.8713C172.801 26.7848 168.585 25.3607 163.947 23.8129C158.366 21.9554 153.666 18.55 153.666 12.3089C153.666 4.82944 160.549 0 169.093 0C173.558 0 178.196 1.30023 180.676 3.09579C181.792 7.49182 181.296 12.99 178.63 12.99C177.886 12.99 177.638 12.6804 177.452 11.6278C176.398 5.80771 172.057 2.91005 167.729 2.91005C163.575 2.91005 160.97 5.57243 160.97 9.22547C160.97 12.8785 164.071 14.4883 168.461 15.9743C173.731 17.7699 180.726 20.2465 180.726 27.8498C180.726 36.0103 172.863 40.0348 164.306 40.0348C159.098 40.0224 154.398 38.5365 151.979 36.7533Z" fill={mainFills.text}/>
          <path d="M228 0.990723L227.752 3.03395C225.086 3.40544 223.535 4.3961 223.225 6.7489L219.939 33.6575C219.629 36.0104 220.931 37.001 223.535 37.3725L223.287 39.4157H207.178L207.426 37.3725C210.092 37.001 211.643 36.0104 211.953 33.6575L213.131 23.9491C213.193 22.587 212.263 22.0297 210.898 22.0297H200.122C198.695 22.0297 197.579 22.6489 197.393 24.073L198.137 17.8814L196.215 33.6575C195.905 36.0104 197.207 37.001 199.812 37.3725L199.564 39.4157H183.454L183.702 37.3725C186.368 37.001 187.919 36.0104 188.229 33.6575L191.515 6.7489C191.825 4.3961 190.523 3.40544 187.919 3.03395L188.167 0.990723H204.276L204.028 3.03395C201.362 3.40544 199.812 4.3961 199.502 6.7489L198.261 17.0146C198.137 18.3148 199.13 18.9339 200.556 18.9339H211.208C212.697 18.9339 213.813 18.3148 213.999 16.8907L213.875 17.8814L215.239 6.7489C215.549 4.3961 214.247 3.40544 211.643 3.03395L211.891 0.990723H228V0.990723ZM198.261 16.9526V16.8907V16.9526Z" fill={mainFills.text}/>
        </g>
        <defs>
          <clipPath id={`url(#clip${renderId})`}>
            <rect width="228" height="53" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    )
  }

  if(type === 'small-square') {
    return (
      <svg viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg" className="small-square-logo">
        <title>small square En Dash Consulting logo</title>
        <path d="M300 0H0V300H300V0Z" fill={fills.bg}/>
        <path d="M300.001 212.119H32.0078V227.705H300.001V212.119Z" fill={fills.line}/>
        <path d="M125.77 152.997L131.299 157.837C126.917 166.371 112.166 180.893 86.5641 180.893C57.7492 180.893 31 162.678 31 127.624C31 91.4233 59.5853 73.4375 88.4002 73.4375C113.063 73.4375 132.447 86.8121 132.447 107.323C132.447 125.538 117.236 128.083 92.7819 129.002L67.4307 129.92C63.2785 130.149 62.3604 133.613 63.7375 138.913C69.4963 155.521 82.8709 164.973 99.9387 164.973C108.702 164.994 117.695 162.678 125.77 152.997ZM60.5243 112.184C60.2948 119.111 62.5899 121.866 68.5991 121.866H80.8261C100.648 121.866 106.657 115.648 106.657 105.027C106.657 93.2594 98.8119 82.2009 84.9783 82.2009C69.2877 82.2217 61.6719 94.6574 60.5243 112.184Z" fill={fills.text}/>
        <path d="M230.2 161.531V119.571C230.2 102.503 223.043 90.9858 208.98 90.9858C194.228 90.9858 185.235 102.524 185.235 119.571V157.608C185.235 167.519 189.158 170.754 199.758 171.672V179.517H142.816V171.672C153.645 170.754 157.568 167.519 157.568 157.608V100.187C157.568 89.5878 153.186 86.8128 142.816 87.7308V79.8855L175.095 73.8972C181.772 72.7496 185.235 75.7333 185.235 81.054C185.235 89.3583 188.929 91.424 191.912 87.9812C196.982 82.4519 206.205 73 224.42 73C241.258 73 257.846 81.3044 257.846 113.583V159.006C257.846 166.622 261.769 169.376 269.614 167.77V175.615C265.462 178.849 256.928 180.685 250.251 180.685C237.586 180.664 230.2 173.988 230.2 161.531Z" fill={fills.text}/>
      </svg>
    )
  }

  return (
    <svg viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg" className="square-logo">
      <title>En Dash Consulting Logo</title>
      <path d="M124 0H0V124H124V0Z" fill={fills.bg}/>
      <path d="M16.9746 103.134C16.3192 102.789 15.8103 102.306 15.4395 101.685C15.0687 101.064 14.8789 100.366 14.8789 99.5894C14.8789 98.8046 15.0687 98.106 15.4395 97.4937C15.8103 96.8814 16.3192 96.3898 16.9746 96.0448C17.6214 95.6998 18.3631 95.5273 19.1824 95.5273C19.8982 95.5273 20.5451 95.6567 21.1143 95.9068C21.6835 96.1569 22.1664 96.5278 22.5459 97.0021L21.1401 98.2785C20.6313 97.6662 20.019 97.3643 19.2945 97.3643C18.8719 97.3643 18.4925 97.4592 18.1648 97.6403C17.837 97.83 17.5783 98.0887 17.3972 98.4251C17.2161 98.7614 17.1213 99.1495 17.1213 99.5894C17.1213 100.029 17.2161 100.417 17.3972 100.754C17.5783 101.09 17.837 101.357 18.1648 101.538C18.4925 101.72 18.8719 101.814 19.2945 101.814C20.019 101.814 20.6313 101.513 21.1401 100.9L22.5459 102.177C22.1664 102.651 21.6921 103.013 21.1143 103.272C20.5365 103.522 19.8982 103.651 19.1824 103.651C18.3545 103.651 17.6214 103.479 16.9746 103.134Z" fill={fills.text}/>
      <path d="M27.2966 103.125C26.6411 102.772 26.1237 102.289 25.7442 101.676C25.3647 101.056 25.1836 100.366 25.1836 99.5894C25.1836 98.8132 25.3733 98.1146 25.7442 97.5023C26.1237 96.8814 26.6411 96.3984 27.2966 96.0534C27.952 95.6998 28.6937 95.5273 29.5216 95.5273C30.3496 95.5273 31.0912 95.6998 31.7467 96.0534C32.4021 96.407 32.9196 96.89 33.2991 97.5023C33.6785 98.1233 33.8596 98.8132 33.8596 99.5894C33.8596 100.366 33.6699 101.056 33.2991 101.676C32.9282 102.297 32.4021 102.78 31.7467 103.125C31.0912 103.479 30.3496 103.651 29.5216 103.651C28.6937 103.651 27.9606 103.479 27.2966 103.125ZM30.5997 101.547C30.9188 101.357 31.1775 101.099 31.3586 100.762C31.5397 100.426 31.6345 100.038 31.6345 99.598C31.6345 99.1582 31.5397 98.7701 31.3586 98.4337C31.1775 98.0974 30.9188 97.83 30.5997 97.6489C30.2806 97.4678 29.9183 97.3729 29.5302 97.3729C29.1335 97.3729 28.7799 97.4678 28.4608 97.6489C28.1417 97.8386 27.8916 98.0974 27.7019 98.4337C27.5121 98.7701 27.4259 99.1582 27.4259 99.598C27.4259 100.038 27.5208 100.426 27.7019 100.762C27.883 101.099 28.1417 101.366 28.4608 101.547C28.7799 101.728 29.1421 101.823 29.5302 101.823C29.9183 101.823 30.2806 101.728 30.5997 101.547Z" fill={fills.text}/>
      <path d="M44.6998 95.6836V103.497H42.88L39.4303 99.3317V103.497H37.2656V95.6836H39.0853L42.5351 99.8491V95.6836H44.6998Z" fill={fills.text}/>
      <path d="M49.402 103.419C48.8069 103.263 48.324 103.056 47.9531 102.798L48.6775 101.168C49.0311 101.4 49.4365 101.582 49.8936 101.728C50.3507 101.875 50.8077 101.944 51.2562 101.944C52.1014 101.944 52.5326 101.728 52.5326 101.306C52.5326 101.081 52.4118 100.918 52.1704 100.805C51.9289 100.702 51.5408 100.581 51.0061 100.469C50.4197 100.34 49.928 100.21 49.5313 100.064C49.1346 99.9171 48.7983 99.6843 48.5137 99.3652C48.2291 99.0461 48.0911 98.6148 48.0911 98.0715C48.0911 97.5972 48.2205 97.166 48.4792 96.7779C48.7379 96.3984 49.126 96.0879 49.6435 95.8637C50.1609 95.6395 50.7991 95.5273 51.5494 95.5273C52.0669 95.5273 52.5671 95.5877 53.0673 95.6998C53.5675 95.8119 54.0073 95.9844 54.3868 96.2087L53.7055 97.8473C52.9638 97.4419 52.2394 97.2436 51.5408 97.2436C51.101 97.2436 50.7819 97.3126 50.5835 97.4419C50.3852 97.5713 50.2817 97.7438 50.2817 97.9508C50.2817 98.1578 50.4024 98.313 50.6353 98.4165C50.8767 98.52 51.2562 98.6321 51.7823 98.7442C52.3774 98.8736 52.8689 99.0029 53.2657 99.1495C53.6538 99.2962 53.9987 99.529 54.2833 99.8395C54.5679 100.15 54.7145 100.59 54.7145 101.133C54.7145 101.599 54.5852 102.03 54.3264 102.41C54.0677 102.789 53.671 103.091 53.1535 103.315C52.6361 103.539 51.9979 103.66 51.2562 103.66C50.6094 103.651 49.9971 103.574 49.402 103.419Z" fill={fills.text}/>
      <path d="M58.8526 102.704C58.2058 102.074 57.8867 101.177 57.8867 100.013V95.6836H60.0945V99.9526C60.0945 101.203 60.5861 101.824 61.5779 101.824C62.5611 101.824 63.0527 101.203 63.0527 99.9526V95.6836H65.2346V100.013C65.2346 101.177 64.9155 102.074 64.2687 102.704C63.6219 103.333 62.7249 103.652 61.5607 103.652C60.405 103.652 59.4995 103.342 58.8526 102.704Z" fill={fills.text}/>
      <path d="M68.9688 95.6836H71.1766V101.746H74.9109V103.497H68.9688V95.6836Z" fill={fills.text}/>
      <path d="M78.9484 97.4343H76.5508V95.6836H83.5537V97.4343H81.1648V103.497H78.957V97.4343H78.9484Z" fill={fills.text}/>
      <path d="M86.6055 95.6836H88.8133V103.497H86.6055V95.6836Z" fill={fills.text}/>
      <path d="M100.059 95.6836V103.497H98.2394L94.7897 99.3317V103.497H92.625V95.6836H94.4447L97.8944 99.8491V95.6836H100.059Z" fill={fills.text}/>
      <path d="M109.097 99.4255H111.055V102.66C110.607 102.979 110.098 103.229 109.511 103.393C108.933 103.565 108.347 103.651 107.769 103.651C106.95 103.651 106.217 103.479 105.561 103.134C104.906 102.789 104.397 102.306 104.018 101.685C103.647 101.064 103.457 100.366 103.457 99.5894C103.457 98.8046 103.647 98.106 104.018 97.4937C104.388 96.8814 104.906 96.3898 105.57 96.0448C106.234 95.6998 106.984 95.5273 107.812 95.5273C108.545 95.5273 109.201 95.6481 109.779 95.8982C110.356 96.1483 110.839 96.5019 111.228 96.959L109.822 98.2354C109.296 97.6575 108.657 97.3643 107.924 97.3643C107.252 97.3643 106.717 97.5713 106.303 97.9766C105.889 98.382 105.691 98.9253 105.691 99.598C105.691 100.029 105.786 100.417 105.967 100.754C106.148 101.09 106.415 101.357 106.752 101.547C107.088 101.737 107.467 101.832 107.899 101.832C108.321 101.832 108.718 101.745 109.089 101.573V99.4255H109.097Z" fill={fills.text}/>
      <path d="M124.001 78.168H13.2305V84.6103H124.001V78.168Z" fill={fills.line}/>
      <path d="M51.5816 53.7321L53.8671 55.7329C52.056 59.2602 45.9586 65.2627 35.3766 65.2627C23.4665 65.2627 12.4102 57.7337 12.4102 43.2449C12.4102 28.2818 24.2254 20.8477 36.1356 20.8477C46.3295 20.8477 54.3414 26.3758 54.3414 34.8535C54.3414 42.3825 48.0543 43.4347 37.9467 43.8141L27.4682 44.1936C25.7519 44.2885 25.3725 45.7201 25.9417 47.9107C28.322 54.7756 33.8501 58.6824 40.9048 58.6824C44.527 58.691 48.2441 57.7337 51.5816 53.7321ZM24.6135 36.863C24.5186 39.7262 25.4673 40.8646 27.9511 40.8646H33.0049C41.198 40.8646 43.6818 38.2946 43.6818 33.9048C43.6818 29.0407 40.4391 24.4699 34.7212 24.4699C28.2357 24.4785 25.0879 29.6186 24.6135 36.863Z" fill={fills.text}/>
      <path d="M94.7474 57.2608V39.9174C94.7474 32.8627 91.7893 28.1021 85.9765 28.1021C79.8791 28.1021 76.1621 32.8713 76.1621 39.9174V55.6394C76.1621 59.736 77.7834 61.0728 82.1646 61.4522V64.6949H58.6289V61.4522C63.1049 61.0728 64.7263 59.736 64.7263 55.6394V31.9054C64.7263 27.5243 62.9152 26.3772 58.6289 26.7567V23.514L71.9707 21.0388C74.7304 20.5645 76.1621 21.7978 76.1621 23.9969C76.1621 27.4294 77.6886 28.2832 78.9218 26.8602C81.0175 24.5748 84.8295 20.668 92.3585 20.668C99.3183 20.668 106.175 24.1004 106.175 37.4422V56.2173C106.175 59.3651 107.796 60.5036 111.039 59.8395V63.0822C109.322 64.419 105.795 65.1779 103.035 65.1779C97.8004 65.1693 94.7474 62.4095 94.7474 57.2608Z" fill={fills.text}/>
    </svg>

  )
};

export default generateLogoSvg;