import { useEffect, useRef, useState } from 'react';
import triangleSvg from '../../../assets/triangle.svg';
import generateLogoSvg from '../../../utils/generateLogoSvg';

const ABItem = ({ start, end, circleColor, showLogo, logoColor, noResponsive }) => {
	const lineRef = useRef(null);
  const [translateXAmount, setTranslateXAmount] = useState('');

	useEffect(() => {
		const viewHeight = document.documentElement.clientHeight;
		const scrollTargetWindow = {
			upper: viewHeight * .4,
			lower: viewHeight * .9,
		};
		const diff = scrollTargetWindow.lower - scrollTargetWindow.upper;
		const lineWidth = lineRef.current.clientWidth - 24;
		
    const onScroll = () => {
			var viewportOffset = lineRef.current.getBoundingClientRect();
			var top = viewportOffset.top;
			const actualPercent = (scrollTargetWindow.lower - top)/diff;
			let cappedPercent = actualPercent < 0 ? 0 : actualPercent;
			cappedPercent = cappedPercent > 1 ? 1 : cappedPercent;
			
			if(window.pageYOffset === 0) {
				cappedPercent = 0;
			} else if (
				Math.ceil((window.innerHeight + window.scrollY)) >= 
				(Math.floor(document.body.offsetHeight) - 1)
			) {
        cappedPercent = 1;
    	}

			setTranslateXAmount(`${Math.floor(cappedPercent * lineWidth)}px`)
		};

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

	return (
		<div className={`ab-item ${noResponsive ? 'no-responsive' : ''}`}>
			<span className="start">{start}</span>
      <div 
				className="line"
				ref={lineRef}>
				<img 
					className='triangle' 
					src={triangleSvg} 
					alt='triangle of progress on a to b line'
					style={{
						transform: `translateX(${translateXAmount})`
					}}/>
				
				{ 
					showLogo ? (
					<div className='logo-wrapper hide-mobile'>
						{ generateLogoSvg(logoColor, 'small-square') }
					</div> 
					) : null 
				}
				<div className='circle' style={ circleColor ? {backgroundColor: `var(--app-color-${circleColor})`} : undefined}></div>
      </div>
			<span className="end">{end}</span>
		</div>
	);
}

export default ABItem;