import { useEffect, useState } from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const SubpageNav = ({logoColor}) => {
  const [floating, setFloating] = useState(false);

  useEffect(() => {
    const onScroll = () => setFloating(window.pageYOffset > 200);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const floatingClass = floating ? 'floating' : '';

  return ( 
    <nav className={`subpage ${floatingClass}`}>
      <DesktopNav logoColor={logoColor} />
      <MobileNav logoColor={logoColor} />
    </nav>
   );
}
 
export default SubpageNav;