import ABItems from "./components/ABItems";
import AppFooter from "./components/AppFooter";
import HowWeDoIt from "./components/HowWeDoIt";
import SubpageHeader from "./components/SubpageHeader";

const pageContent = {
	variant: {  
		key: 'software-development',
    title: 'Software Development',
    logoColor: 'orange',
    headerBgColor: 'gray',
    headerTextColor: 'blue',
    imgAlt: 'Graphic of computer and tablet with shapes',
  },
	summary: (
		<>
			<p className="page-content padded">At En Dash, we focus on finding the right tool for the job, and if it doesn't exist, we want to build it. We strive for intuitive design and development to make tools that enable users to be more productive and efficient.</p>
			<p className="page-content padded">Our team is passionate about contributing to others by providing software solutions that improve the lives of users.</p>
		</>
	),
	abHeadline: 'Software should solve problems',
	abContent: [
		{
			start: 'Disconnect between Product and Technology',
			end: 'Product-concious software development',
		},
		{
			start: 'Isolated, siloed developers',
			end: 'Inclusive, engaged team',
		},
		{
			start: 'Unable to adapt to new technologies',
			end: 'Learning-first mindset',
		},
		{
			start: 'Disconnected with users and your product',
			end: 'Feature-driven, user-centric development',
		},
		{
			start: 'Disorganized code practices',
			end: 'Versioned, manageable, software-development flow',
		},
		// {
		// 	start: 'Afraid of upgrading packages',
		// 	end: 'Embedded dependency-updates in workflow',
		// },
	],
	howWeDoItContent: [
		{
			heading: 'Your Partner',
			text: 'Let us help you where you are, applying software to solve the problems relevant to you by leading and/or integrating with your tech teams.',
		},
		{
			heading: 'Your Expert',
			text: 'Give your tech team a different perspective; we provide an initial analysis and any accompanying seminars or training sessions to enable and revitalize your web-application development.',
		},
	]
};


const SoftwarePage = () => {
	return ( 
		<>
			<SubpageHeader variant={pageContent.variant} />
			<main className="page-content">
				{ pageContent.summary }

				<ABItems 
					items={pageContent.abContent} 
					headline={pageContent.abHeadline} 
					circleColor={pageContent.variant.logoColor}
					logoColor={pageContent.variant.logoColor} />

				<HowWeDoIt 
					iconKey={pageContent.variant.key}
					headingColor={pageContent.variant.logoColor}
					content={pageContent.howWeDoItContent} />
			</main>
			<AppFooter />
		</>
	);
}
 
export default SoftwarePage;