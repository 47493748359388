const AppBackgroundBlob = ({
  color,
  position,
  styleChoice = 0,
  width,
}) => {
  const styleChoices = [
    <svg viewBox="0 0 456 573" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M231.66 489.562C259.641 432.487 296.428 384.534 340.965 347.079C368.619 323.822 399.284 304.582 422.564 275.598C445.843 246.613 461.467 205.685 453.003 159.884C449.25 140.85 441.494 121.18 430.516 102.85C419.538 84.5208 405.722 68.1762 390.457 55.4575C360.258 29.4491 325.595 14.9048 291.698 7.00648C193.433 -15.254 98.8144 21.5482 46.8653 103.05C-5.08374 184.551 -13.7919 307.295 25.0913 422.67C35.65 453.957 45.2276 487.072 62.8173 516.271C73.7048 534.864 88.4386 550.684 104.377 560.896C127.313 575.158 159.586 576.67 178.62 565.606C196.671 555.069 208.523 533.774 219.144 514.406C223.472 506.117 227.597 497.882 231.66 489.562Z" fill={color} />
    </svg>,
    <svg viewBox="0 0 1375 674" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M362.016 601.212C507.681 588.509 654.403 596.189 797.944 624.03C886.947 641.173 975.033 666.142 1065.41 672.32C1155.79 678.497 1252.37 663.246 1312.63 610.544C1337.17 588.327 1355.45 560.055 1365.63 528.554C1375.82 497.054 1377.55 463.432 1370.67 431.051C1358 366.196 1319 305.361 1269.83 252.113C1126.3 96.2622 892.086 -0.587081 662.197 0.475473C432.309 1.53803 213.165 100.451 93.8236 256.99C61.4392 299.595 23.9353 342.451 7.87934 392.075C-2.83099 423.853 -1.86121 458.409 10.6144 489.534C29.136 533.904 85.8984 579.744 138.809 595.84C189.386 611.264 246.242 608.588 297.701 605.789C319.2 604.604 340.438 602.939 362.016 601.212Z" fill={color} />
    </svg>,
  ];

  return ( 
    <div 
      className="app-background-blob" 
      style={{
        transform: `translateX(${position.x}) translateY(${position.y})`,
        width: width ?? '450px',
      }}>
      {
        styleChoices[styleChoice]
      }
    </div>
   );
}
 
export default AppBackgroundBlob;