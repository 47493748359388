import generateLogoSvg from '../../utils/generateLogoSvg';
import Sandy from '../../assets/Sandy.svg';
import Pebble from '../../assets/Pebble.svg';
import Lola from '../../assets/Lola.svg';
import Kirby from '../../assets/Kirby.svg';
import Valkyrie from '../../assets/Valkyrie.svg';
import Buddy from '../../assets/Buddy.svg';
import NavUnorderedList from './Nav/NavUnorderedList';

const AppFooter = ({styleVariant}) => {
  const uiConfigs = [
    {
      backgroundColor: 'blue',
      textColor: 'white',
      logoColors: {
        text: '#FFFFFF',
        line: '#FFFFFF',
      },
      pets: [
        {
          key: 'sandy',
          src: Sandy,
          altText: 'Sandy, a white hound dog',
        },
        {
          key: 'pebble',
          src: Pebble,
          altText: 'Pebble, a small spotted dog',
        },
      ]
    },
    {
      backgroundColor: 'teal',
      focusColor: 'blue',
      textColor: 'blue',
      logoColors: {
        text: '#001769',
        line: '#001769',
      },
      pets: [
        {
          key: 'lola',
          src: Lola,
          altText: 'Lola, a white cat',
        },
        {
          key: 'kirby',
          src: Kirby,
          altText: 'Kirby, a chinchilla',
        },
      ]
    },
    {
      backgroundColor: 'gray',
      textColor: 'blue',
      logoColors: {
        text: '#001769',
        line: '#001769',
      },
      pets: [
        {
          key: 'valkyrie',
          src: Valkyrie,
          altText: 'Valkyrie, a dark portuguese water dog',
        },
        {
          key: 'buddy',
          src: Buddy,
          altText: 'Buddy, a black cat',
        },
      ]
    }
  ]

  const uiSelection = styleVariant ?? (Math.floor((Math.random() * 100)) % 3);
  const selectedConfig = uiConfigs[uiSelection];

  const footerClass = selectedConfig.focusColor ? `${selectedConfig.focusColor}-focus` : undefined;
  return ( 
    <footer
      className={footerClass}
      style={{
        backgroundColor: `var(--app-color-${selectedConfig.backgroundColor})`,
        color: `var(--app-color-${selectedConfig.textColor})`,
        fill: 'currentColor',
      }}>
      <div className="page-content">
        <div className="left-footer">
          <a className="logo" aria-label="Home" href='/'>
            {generateLogoSvg(selectedConfig.logoColors, 'main')}
          </a>
          <span>En Dash helps bridge technology gaps. Partner with us to empower your business with innovative solutions.</span>
          <div className="footer-contact">
            <a href="https://www.linkedin.com/company/endash-us/">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>linkedin logo</title>
                <path d="M4.47748 20H0.330342V6.64732H4.47659L4.47748 20ZM2.40123 4.82589C1.0754 4.82589 0 3.72768 0 2.40179C0 1.76479 0.253033 1.15389 0.703435 0.703467C1.15384 0.253045 1.76471 0 2.40168 0C3.03864 0 3.64952 0.253045 4.09992 0.703467C4.55032 1.15389 4.80336 1.76479 4.80336 2.40179C4.80336 3.72768 3.72751 4.82589 2.40213 4.82589H2.40123ZM19.9946 20H15.8573V13.5C15.8573 11.9509 15.8261 9.96429 13.7016 9.96429C11.5459 9.96429 11.2156 11.6473 11.2156 13.3884V20H7.07379V6.64732H11.0504V8.46875H11.1084C11.662 7.41964 13.0142 6.3125 15.0315 6.3125C19.2277 6.3125 20 9.07589 20 12.6652V20H19.9946Z" fill="currentColor"/>
              </svg>
            </a>
            <a href="mailto:hello@endash.us">
              <svg title="envelope icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 13.75C9.3543 13.75 8.70859 13.5382 8.15781 13.109L0 6.76562V15.625C0 16.6602 0.839453 17.5 1.875 17.5H18.125C19.1605 17.5 20 16.6605 20 15.625V6.76562L11.8438 13.1133C11.293 13.5391 10.6445 13.75 10 13.75ZM0.636328 5.67578L8.92539 12.125C9.55781 12.6172 10.4437 12.6172 11.0762 12.125L19.3652 5.67578C19.7305 5.36328 20 4.88281 20 4.375C20 3.33945 19.1602 2.5 18.125 2.5H1.875C0.839453 2.5 0 3.33945 0 4.375C0 4.88281 0.234766 5.36328 0.636328 5.67578Z" fill="currentColor"/>
              </svg>
            </a>
            <a href="tel:+18042063136">804.206.3136</a>
          </div>

          <span>© {(new Date()).getFullYear()} En Dash. All rights reserved.</span>
        </div>
        <div className="footer-nav">
          <NavUnorderedList contactLink />
        </div>
        {
          selectedConfig.pets.map(pet => (
            <img 
              key={pet.key} 
              className={`pet-image ${pet.key}`} 
              src={pet.src} 
              alt={pet.altText} />
          ))
        }
      </div>
    </footer>
  );
}
 
export default AppFooter;