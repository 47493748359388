import { Link } from "react-router-dom";
import { openLetsConnectModal } from "../LetsConnectModal";

const links = [
  {
    route: '/software-development',
    label: 'Software Development',
  },
  {
    route: '/product-thinking',
    label: 'Product Thinking',
  },
  {
    route: '/accessible-design',
    label: 'Accessible Design',
  },
]

const NavUnorderedList = ({callbackFn, contactLink}) => {
  const clickHandler = () => {
    if(callbackFn) {
      callbackFn();
    }
  }
  const openModal = () => {
    openLetsConnectModal();
  }
  
  return ( 
    <ul>
      {
        links.map(link => (
          <li key={link.route}>
            <Link 
              to={link.route}
              onClick={clickHandler}>
              {link.label}
            </Link>
          </li>
        ))
      }
      {
        contactLink && (
          <li>
            <button 
              className='no-button-styling' 
              onClick={openModal}>
                Contact
            </button>
          </li>
        )
      }
    </ul>
   );
}
 
export default NavUnorderedList;