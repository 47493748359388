import { useRef, useState } from "react";
import AppInputField from "./AppInputField";
import axios from "axios";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AppContactForm = ({
  successHandler,
  isSpecialEvent,
  eventParam
}) => {
  const [fullName, setFullName] = useState('');
  const fullNameRef = useRef(null);
  const [companyName, setCompanyName] = useState('');
  const companyNameRef = useRef(null);
  const [email, setEmail] = useState('');
  const emailRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberRef = useRef(null);
  const [message, setMessage] = useState('');
  const messageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const allRefs = [
    fullNameRef,
    companyNameRef,
    emailRef,
    phoneNumberRef,
    messageRef,
  ]

  const sendPost = async () => axios.post('/submit', {
    fullName,
    companyName,
    email,
    phoneNumber,
    message,
    eventParam
  });

  const submitHandler = async (event) => {
    event.preventDefault(); // prevents default browser form submission behavior

    if(validateForm()){
      setFormError('');
      setIsLoading(true);
      try { 
        await sendPost();
        setIsLoading(false);
        successHandler(true);
      } catch (error) {
        setIsLoading(false);
        setFormError('Form submission failed. Please try again later.');
        console.error(error,"sendPostFunction failed");
      }
    } else {
      setFormError('Fix all form issues and try submitting again.');
    }
  }

  const validateForm = () => {
    let firstInvalidInput;
    let isValid = true;
    allRefs.forEach(ref => {
      if(ref.current) {
        const isInputValid = ref.current.validate();
        if(!isInputValid && !firstInvalidInput) {
          firstInvalidInput = ref;
        }
        isValid = isInputValid && isValid;
      }
    });

    if(firstInvalidInput) {
      firstInvalidInput.current.focus();
    }
    return isValid;
  }

  return ( 
    <form className="app-contact-form">
      <div className="input-grid">
        <AppInputField
          disabled={isLoading}
          label="Name"
          name="full-name"
          customErrorMessage='Name is required.'
          ref={fullNameRef}
          value={fullName}
          setter={setFullName}
          required={true} />
        
        {
          isSpecialEvent ?
          null :
          <AppInputField
            disabled={isLoading}
            label="Company Name"
            name="company-name"
            ref={companyNameRef}
            value={companyName}
            setter={setCompanyName}
            required={false} />
        }
        
        <AppInputField
          disabled={isLoading}
          label="Email"
          name="email"
          type="email"
          customErrorMessage='Valid email address required.'
          customValidator={(val) => emailRegex.test(val)}
          ref={emailRef}
          value={email}
          setter={setEmail}
          required={true} />
        
        {
          isSpecialEvent ?
          null :
          <AppInputField
            disabled={isLoading}
            label="Phone Number"
            name="phone-number"
            type="tel"
            ref={phoneNumberRef}
            value={phoneNumber}
            setter={setPhoneNumber}
            required={false} />
        }

        <AppInputField
          disabled={isLoading}
          label={isSpecialEvent ? 'Your thoughts on Async Collaboration' : "Subject; How can we help?"}
          name="message"
          customErrorMessage='You gotta give us something.'
          ref={messageRef}
          value={message}
          setter={setMessage}
          required={true}
          textarea />
      </div>
      <button 
        disabled={isLoading} 
        className="bg-purple" 
        type="submit" 
        onClick={submitHandler}>Submit
      </button>
      {
        formError 
          ? <span className="form-error-message">{ formError }</span>
          : null
      }
    </form>
   );
}
 
export default AppContactForm;