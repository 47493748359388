import { useEffect } from "react";
import { pregeneratedSvgs } from "../../utils/generateHeaderBgSvg";
import SubpageNav from "./Nav/SubpageNav";

const SubpageHeader = ({variant}) => {
  const { title, logoColor, headerBgColor, headerTextColor, imgAlt } = variant;

  useEffect(() => {
    document.title = `${title} \u2014 En Dash Consulting`;
  }, [title]);

  return ( 
    <>
    <SubpageNav logoColor={logoColor} />
    <header className="subpage">
      { pregeneratedSvgs[headerBgColor] }
      <div className="header-content page-content">
        <h1 
          className={`page-content text-${headerTextColor}`}
          tabIndex="-1">
          {title ?? 'Missing Title'}
        </h1>
        <div className="svg-wrapper">
          <link rel="preload" fetchpriority="high" as="image" href={require(`../../assets/subpage_${variant.key}.svg`)} type="image/svg+xml" />
          <img src={require(`../../assets/subpage_${variant.key}.svg`)} alt={imgAlt} />
        </div>
      </div>
    </header>
    </>
   );
}
 
export default SubpageHeader;