import ABItems from "./components/ABItems";
import AppFooter from "./components/AppFooter";
import HowWeDoIt from "./components/HowWeDoIt";
import SubpageHeader from "./components/SubpageHeader";

const pageContent = {
	variant: {
		key: 'product-thinking',
		title: 'Product Thinking',
		logoColor: 'blue',
		headerBgColor: 'pink',
		headerTextColor: 'white',
		imgAlt: 'Graphic of multiple people having conversation across different tech devices, along with chat message bubbles for chat conversations',
	},
	summary: (
		<>
			<p className="page-content padded">At En Dash, we believe that the relationship between product and software development is a collaborative one. Product thinking is agile and iterative; it quickly adapts to the fast-paced marketplace.</p>
			<p className="page-content padded">Our approach to product development, backlog management and the agile framework keeps the user at the center of everything while refining processes to empower teams to deliver impactful products in an efficient and collaborative way.</p>
		</>
	),
	abHeadline: 'Adopt a positive product mindset',
	abContent: [
		{
			start: 'Bogged down by bloated features',
			end: 'Clear-cut and lean feature definition',
		},
		{
			start: 'Undefined intent',
			end: 'Clear development path',
		},
		{
			start: 'Searching for Product Design experience',
			end: 'In-house know-how',
		},
		{
			start: 'Lack of traction in the marketplace',
			end: 'A product that resonates',
		},
		{
			start: 'Stuck with a boilerplate app',
			end: 'A true “Minimum Lovable Product”',
		},
		{
			start: 'Constant meetings',
			end: 'Async bliss',
		},
	],
	howWeDoItContent: [
		{
			heading: 'Your Partner',
			text: 'Hire us to work on your teams alongside your employees to model product thinking, agile practices and improved efficiency.',
		},
		{
			heading: 'Your Expert',
			text: 'We will examine your current portfolio, processes, teams etc and work with you to implement incremental changes to help increase efficiency and effectiveness.',
		},
	]
};


const ProductPage = () => {
	return ( 
		<>
			<SubpageHeader variant={pageContent.variant} />
			<main className="page-content">
				
				{ pageContent.summary }
				
				<ABItems 
					items={pageContent.abContent} 
					headline={pageContent.abHeadline} 
					circleColor={pageContent.variant.headerBgColor}
					logoColor={pageContent.variant.logoColor} />
				
				<HowWeDoIt 
					iconKey={pageContent.variant.key}
					headingColor={pageContent.variant.headerBgColor}
					content={pageContent.howWeDoItContent} />
					
			</main>
			<AppFooter />
		</>
	);
}
 
export default ProductPage;