import AppIcon from "./AppIcon";

const HowWeDoIt = ({content, iconKey, headingColor}) => {
  return ( 
    <section className="how-we-do-it">
      <h2>
        <AppIcon 
          name={iconKey ?? 'product-thinking'} 
          offsetY={'3px'} 
          offsetX={'10px'} />
        How We Do It
      </h2>

      <div className="tiles">
        {
          content.map((item, index) => (
            <div key={index}>
              <h3 style={ headingColor ? {color: `var(--app-color-${headingColor})`} : undefined} >{item.heading}</h3>
              <p>{item.text}</p>
            </div>
          ))
        }
      </div>
    </section>
   );
}
 
export default HowWeDoIt;