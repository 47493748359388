const AppIcon = ({name, offsetY, offsetX}) => {
  const altString = `${name} icon`;
  const style = {};
  if(offsetY || offsetY === 0) {
    style['--offset-y'] = offsetY;
  }
  if(offsetX || offsetX === 0) {
    style['--offset-x'] = offsetX;
  }
  return (
    <div 
      className="app-icon" 
      style={style}
      >
      <img 
        src={require(`../../assets/icon_${name}.svg`)} 
        alt={altString} 
        title={altString}
        />
    </div>
  )
}

export default AppIcon;